import { makeStyles } from "@mui/styles";

export const useStylesMobile = makeStyles((theme) => ({
  title: {
    fontFamily: theme.typography.title.fontFamily,
    fontStyle: theme.typography.title.fontStyle,
    fontWeight: "400",
    fontSize: "30px",
    lineHeight: "26px",
    color: "#FFFFFF",
    padding: "3% 0 2% 0",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "16px 24px !important",
    gap: "10px",
    backgroundColor: "#FFFFFF !important",
    borderRadius: "32px !important",
    color: "#00B7E3 !important",
  },
  bayleyssvg: {
    display: "block",
    margin: "80px auto",
    width: "auto",
    marginBottom: "15px",
  },
  bayleys: {
    fill: "#E10000",
    stroke: "#E10000",
    strokeWidth: "0",
    animation: "$logo 3s linear infinite",
  },
  bayleys2: {
    fill: "#E10000",
    stroke: "#E10000",
    strokeWidth: "0",
    animation: "$logo 3s linear infinite",
  },
  bayleys3: {
    fill: "#00B7E4",
    stroke: "#00B7E4",
    strokeWidth: "0",
    animation: "$logo 3s linear infinite",
  },
  bayleys4: {
    fill: "#00B7E4",
    stroke: "#00B7E4",
    strokeWidth: "0",
    animation: "$logo 3s linear infinite",
  },
  "@keyframes logo": {
    "0%": {
      strokeWidth: "1pt",
      strokeDashoffset: "1200",
      fillOpacity: "0",
    },
    "50%": {
      fillOpacity: "0",
    },
    "100%": {
      strokeDashoffset: "0",
      fillOpacity: "1",
      strokeWidth: "0pt",
    },
  },
}));
