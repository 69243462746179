import { Button, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { PinappBlue } from "../../assets/icon-svg";
import { Link } from "react-router-dom";
import { isBrowser, isMobile, isTablet } from "react-device-detect";
import { useStyles } from "./styles/progress.styled.js";
import { useStylesMobile } from "./styles/progress.styled.mobile";

const LayoutContainer = styled.div`
  background-image: linear-gradient(to right, #00b7e3, #001568);
  width: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
`;

const LayoutContainerMobile = styled.div`
  background-image: linear-gradient(to right, #00b7e3, #001568);
  width: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: space-around;
  justify-content: space-around;
`;

const Progress = () => {
  const classes = useStyles();
  if (isBrowser) {
    return (
      <LayoutContainer>
        <PinappBlue classes={classes} />
        <Typography variant="p" gutterBottom={true} className={classes.title}>
          Sitio en construcción
        </Typography>
        <Button
          variant="contained"
          component={Link}
          className={classes.button}
          to="/"
        >
          Regresar a inicio
        </Button>
      </LayoutContainer>
    );
  } else if (isMobile || isTablet) {
    const classesMobile = useStylesMobile();
    return (
      <LayoutContainerMobile>
        <PinappBlue classes={classes} />
        <Typography variant="p" gutterBottom={true} className={classesMobile.title}>
          Sitio en construcción
        </Typography>
      </LayoutContainerMobile>
    );
  }
};

export default Progress;
