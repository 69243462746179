import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
      width: "auto",
      height: "auto",
    },
    title: {
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "56px",
      lineHeight: "72px",
      color: "#001568",
    },
  },
});

export default theme;
