import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./theme";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import CircularStatic from "./components/spinner/CircularStatic";
import Progress from "./pages/Progress/Progress";


const Inicio = lazy(() => import("./pages/Inicio/Inicio"));
const Nosotros = lazy(() => import("./pages/Nosotros/Nosotros"));
const Servicios = lazy(() => import("./pages/Servicios/Servicios"));
const Casos = lazy(() => import("./pages/Casos/Casos"));
const Contacto = lazy(() => import("./pages/Contacto/Contacto"));
const Sumate = lazy(() => import("./pages/Sumate/Sumate"));

const App = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<CircularStatic/>}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Inicio />} />
              <Route path={`casos`} element={<Casos/>} />
              {/* <Route path={`contacto`} element={<Contacto />} /> */}
              <Route path={`nosotros`} element={<Nosotros />} />
              <Route path={`servicios`} element={<Servicios/>} />
              <Route path={`sumate`} element={<Sumate/>} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
